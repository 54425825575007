import React, { useState, useEffect } from 'react';
import io from 'socket.io-client';
import { useAuth } from '../../context/AuthContext';
import './Dashboard.css';

const DashboardPage = () => {
  const [data, setData] = useState([]);
  const [sortField, setSortField] = useState('pc_id');
  const [sortDirection, setSortDirection] = useState('asc');
  const { isAuthenticated } = useAuth();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch('/api/data', {
          method: 'GET',
          credentials: 'include',
        });
        if (response.ok) {
          const initialData = await response.json();
          setData(Array.isArray(initialData) ? initialData : [initialData]);
        } else {
          console.error('Failed to fetch initial data');
        }
      } catch (error) {
        console.error('Failed to fetch initial data', error);
      }
    };

    if (isAuthenticated) {
      fetchData();

      const socket = io('https://monitor.neurocess.co', {
        withCredentials: true,
        reconnection: true,
        reconnectionAttempts: 10,

      });

      socket.on('connect', () => {
        console.log('Connected to socket');
      });

      socket.on('update', (newData) => {
        console.log('Received updated data:', newData);
        setData(Array.isArray(newData) ? newData : [newData]);
      });
      socket.on('disconnect', () => {
        console.log('Disconnected from socket');
      })

      return () => {
        socket.disconnect();
      };
    }
  }, [isAuthenticated]);

  const sortData = (data) => {
    return data.sort((a, b) => {
      const aValue = a[sortField];
      const bValue = b[sortField];
  
      // Check if both values are numbers or if both are not numbers (i.e., strings or undefined)
      const bothAreNumbers = !isNaN(parseFloat(aValue)) && !isNaN(parseFloat(bValue));
      const neitherIsNumber = isNaN(parseFloat(aValue)) && isNaN(parseFloat(bValue));
  
      if (bothAreNumbers) {
        // Compare as numbers
        return sortDirection === 'asc' ? aValue - bValue : bValue - aValue;
      } else if (neitherIsNumber) {
        // Handle as strings, including cases where values might be undefined
        const strA = aValue || '';
        const strB = bValue || '';
        const matchA = strA.toString().match(/(\D*)(\d*)/);
        const matchB = strB.toString().match(/(\D*)(\d*)/);
        const prefixA = matchA[1], numA = parseInt(matchA[2], 10);
        const prefixB = matchB[1], numB = parseInt(matchB[2], 10);
  
        // Compare prefixes
        if (prefixA < prefixB) return sortDirection === 'asc' ? -1 : 1;
        if (prefixA > prefixB) return sortDirection === 'asc' ? 1 : -1;
  
        // If prefixes are equal and both have numbers, compare the numbers
        if (!isNaN(numA) && !isNaN(numB)) {
          if (numA < numB) return sortDirection === 'asc' ? -1 : 1;
          if (numA > numB) return sortDirection === 'asc' ? 1 : -1;
        }
  
        // If one item has a number and the other doesn't, or both are exactly the same string
        return 0;
      } else {
        // One value is a number and the other is not; numbers are sorted first
        return isNaN(parseFloat(aValue)) ? 1 : -1;
      }
    });
  };
  

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
    } else {
      setSortField(field);
      setSortDirection('asc');
    }
  };

  const safeAccess = (item, field) => (item[field] ? item[field] : 'N/A');

  const sortedData = sortData([...data]);

  return (
    <div style={{ padding: '20px',textAlign: 'center' }}>
      <button className="logoutButton"
        onClick={() => {
          fetch('/api/logout', {
            method: 'POST',
            credentials: 'include',
          }).then(() => {
            window.location.reload();
          });
        }}
      >
        Logout
      </button>
      <h1 style={{ fontFamily: '"Segoe UI", Arial, sans-serif', color: 'white' }}>Dashboard</h1>
      <table className="modernTableStyle">
        <thead>
          <tr>
            <th className="modernHeaderStyle" aria-label="Sort by PC ID" onClick={() => handleSort('pc_id') }>
              PC ID {sortField === 'pc_id' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
            </th>
            {/* <th className="modernHeaderStyle" onClick={() => handleSort('local_port_db')}>
              Local Port DB {sortField === 'local_port_db' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th className="modernHeaderStyle" onClick={() => handleSort('local_port_ssh')}>
              Local Port SSH {sortField === 'local_port_ssh' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
            </th> */}
            <th className="modernHeaderStyle" onClick={() => handleSort('status')}>
              Status {sortField === 'status' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th className="modernHeaderStyle" onClick={() => handleSort('team_name')}>
              Team Name {sortField === 'team_name' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
            </th>
            <th className="modernHeaderStyle" onClick={() => handleSort('virtual_ip')}>
              Virtual IP {sortField === 'virtual_ip' ? (sortDirection === 'asc' ? '↑' : '↓') : ''}
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedData.map((item, index) => (
            <tr key={index} className="rowHover">
              <td className="tableData">{safeAccess(item, 'pc_id')}</td>
              {/* <td className="tableData">{safeAccess(item, 'local_port_db')}</td>
              <td className="tableData">{safeAccess(item, 'local_port_ssh')}</td> */}
              <td
                className={`tableData ${
                  item.status === 'ONLINE' ? 'statusOnline' : item.status === 'OFFLINE' ? 'statusOffline' : ''
                }`} style = {item.status === 'ONLINE' ? {backgroundColor: '#4CAF50'} : item.status === 'OFFLINE' ? {backgroundColor: '#F44336'} : {backgroundColor: '#111111'}}
              >
                {safeAccess(item, 'status')}
              </td>
              <td className="tableData">{safeAccess(item, 'team_name')}</td>
              <td className="tableData">{safeAccess(item, 'virtual_ip')}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DashboardPage;
