import React, { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    checkAuthStatus();
  }, []);

  useEffect(() => {
    console.log('Auth status changed:', isAuthenticated);
  }, [isAuthenticated]);

  const login = async (username, password) => {
    console.log('Logging in user:', username);
    const response = await fetch('/api/auth', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username, password }),
      credentials: 'include', // Ensure credentials are included for cookies
    });
    if (response.ok) {
      setIsAuthenticated(true);
      setUser({ username });
      // No need to setToken here as it's HTTP-only
    } else {
      alert('Login failed');
    }
  };

  const logout = async () => {
    const response = await fetch('/api/logout', {
      method: 'POST',
      credentials: 'include', // Ensure credentials are included to clear the HTTP-only cookie
    });
    if (response.ok) {
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  const checkAuthStatus = async () => {
    const response = await fetch('/api/status', {
      credentials: 'include', // Needed to include HTTP-only cookies in the request
    });
    if (response.ok) {
      setIsAuthenticated(true);
      const data = await response.json();
      setUser({ username: data.username }); // Assuming the server sends back the username
    } else {
      setIsAuthenticated(false);
      setUser(null);
    }
  };

  return <AuthContext.Provider value={{ isAuthenticated, user, login, logout }}>{children}</AuthContext.Provider>;
};

export const useAuth = () => useContext(AuthContext);
