import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext'; // Adjust the path based on your file structure
import './Login.css';
export default function LoginPage() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const auth = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (auth.isAuthenticated) {
      console.log('Redirecting to dashboard...');
      navigate('/dashboard'); // Redirect to dashboard if login is successful
    }
  }, [auth.isAuthenticated, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Logging in user:', username);
    await auth.login(username, password);
  };

  return (
    <div>
      <div className="login-container">
        <div className="login-image">
          <h1 style={{ fontSize: '5vh', color: 'white'}}>Monitor App</h1>
          <img src="NEUROCESS_LOGO_horizontal_color_and_white_clear.png" alt="Login Visual" />
        </div>
        <div className="login-form">
          <h2 style={{ fontSize: '5vh', color: 'white' }}>Login</h2>
          <form onSubmit={handleSubmit}>
            <div className="form-group">
              <label>Username:</label>
              <input type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <div className="form-group">
              <label>Password:</label>
              <input type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <button type="submit">Login</button>
          </form>
        </div>
      </div>{' '}
    </div>
  );
}
